// All variables that are used throughout the CSS.
//
// This file also overrides Bootstrap variables.
// For a complete overview of all variables to override, see:
// * node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
// * https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss

// mixins (using webpack root)
$image-url-path: '../images/';
$fonts-url-path: '../fonts/';

$enable-dark-mode: false;

// Typography
$font-family-sans-serif: "Lato", "Arial", sans-serif;
$font-size-base: 1.19rem;
$line-height-base: 1.7;

// $blockquote-font-size: 2rem;
// $small-font-size: .805rem;

// Base colors
$body-bg: #fff;
$body-color: #4f4f4f;
//$body-emphasis-color: $black;
//$primary: ...
//$secondary: ...

// Titles
$headings-font-family: "Lato", "Arial", sans-serif;
//$headings-font-weight: 300;
//$headings-color: $gray-darker;

//$link-color: $primary;
//$link-hover-color: $gray-dark;

$container-max-widths: (
  // Full width on tablet and mobile:
  //sm: 540px,
  //md: 720px,
  lg: 960px,
  xl: 1140px
);

// Breadcrumb
//$breadcrumb-bg: $gray-light;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-margin-bottom: 0;
$breadcrumb-border-radius: 0;

// Navbar
//$navbar-brand-font-size: 1.625rem; // 26px
$navbar-brand-padding-y: 1rem; // mobile

//$navbar-light-color: $gray-darker;
//$navbar-light-active-color: $orange;

//$navbar-light-hover-color: $primary;
//$navbar-light-brand-color: $primary;
//$navbar-light-brand-hover-color: $orange-dark;

// Header
//$header-margin: 1rem;
//$header-margin-md: 2rem;
//$header-margin-lg: 3rem;
//$header-margin-xl: 5rem;
//$navbar-brand-padding-y: 0;

// Strip
$-bg-strip: #fbbd60;

// Forms
//$form-group-margin-bottom: 1.5rem;
//$input-padding-x: 1.5rem;
//$input-padding-y: 0.75rem;
//$input-btn-padding-x: 1.5rem;
//$input-btn-padding-y: 0.75rem;
//$input-border-color: rgba(0, 0, 0, 0.2);

// --- stretch page by default

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth; // Smooth anchor scrolling!
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto; // Jump scrolling if people configured that
  }
}

#wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#main {
  flex-grow: 1; // max height on small pages, push footer down
  position: relative;
  margin-bottom: 2rem;
}

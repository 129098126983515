.contact-form {
  max-width: 50rem;
  margin: 0 auto 2rem auto;

  .form-group {
    margin-bottom: 20px;  // for top-row
  }
}

.contact-form-done {
  text-align: center;  // for separate pages

  .text-success {
    color: $success;
  }
}

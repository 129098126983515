#brand {
  margin-bottom: 3rem;
  text-align: center;
}

#frontpage {
  text-align: center;
  margin: 5rem auto 0 auto;
  max-width: 800px;

  h1 {
    margin-top: 0;
    font-size: $h5-font-size;
  }
}

#personal {
  margin-bottom: 2rem;
}

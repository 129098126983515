@charset "utf-8";

// Font files created with https://transfonter.org/

// Lato (body text)

@font-face {
    font-family: 'Lato';
    src: font-url('subset-Lato-Regular.woff2') format('woff2'),
        font-url('subset-Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: font-url('subset-Lato-Bold.woff2') format('woff2'),
        font-url('subset-Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: font-url('subset-Lato-Italic.woff2') format('woff2'),
        font-url('subset-Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: font-url('subset-Lato-BoldItalic.woff2') format('woff2'),
        font-url('subset-Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: font-url('subset-Lato-Light.woff2') format('woff2'),
        font-url('subset-Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: font-url('subset-Lato-LightItalic.woff2') format('woff2'),
        font-url('subset-Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

.media {
  display: flex;
  justify-content: center;

  figure {
    margin-left: 1rem;
    max-width: 10rem;
  }
}

.media-body {
  align-self: center;
}
